import React from "react"
import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import getAppUrl from "@src/utils/getAppUrl"
import Section from "@src/components/Section"
import FeatureGrid from "@src/components/FeatureGrid"
import Text from "@src/components/Text"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import track from "@src/utils/track"

import MVPIllustration from "@src/icons/v2/mvp-illustration.svg"
import TimeConsumingIcon from "@src/icons/v2/time-consuming-integrations.svg"
import AggregatedDataIcon from "@src/icons/diversity.svg"
import SaveTimeIcon from "@src/icons/v2/save-time.svg"
import OG from "@src/icons/og/OG_MVP.jpeg"

import * as styles from "./styles.module.scss"
import Splash from "./components/Splash"
import Row from "../components/Row"
import Features from "./components/Features"

const MVP = ({ location }) => {
  const mainAppUrl = getAppUrl(location, "/dashboard/marketplace/integrations")

  return (
    <Layout
      title="Get the most from your prototype with Lowcode Platform"
      description="Quick iterations, faster go-to-market, and more. It takes a lot of effort and time to build Minimum viable products. Overcome these challenges with Canonic's low code platform."
      url="https://canonic.dev/features/mvp"
      location={location}
      ogImage={OG}
    >
      <Splash
        title="Get the most from your prototype with Lowcode Platform"
        subtitle="Quick iterations, faster go-to-market, and more. It takes a lot of effort and time to build Minimum viable products. Overcome these challenges with Canonic's low code platform."
        illustrationComponent={<MVPIllustration />}
      />
      <Row>
        <h2 className={styles.title}>Building MVPs: Roadblocks</h2>
        <p className={styles.subtitle}>Challenges</p>
        <Features
          horizontal
          numPerRow={3}
          features={[
            {
              icon: TimeConsumingIcon,
              title: "Time-consuming",
              subtitle:
                "Manually coding and building your MVP will take a while, which will delay you from getting ready with your product and getting feedback from users.",
            },
            {
              icon: AggregatedDataIcon,
              title: "Slow iterations",
              subtitle:
                "MVPs are expected to receive constant feedback from users, but slow iteration can cause delays.",
            },
            {
              icon: SaveTimeIcon,
              title: "Platform limitations",
              subtitle:
                "Adding features to your MVP that the platform doesn't support is difficult.",
            },
          ]}
        />
      </Row>

      <Row>
        <h2 className={styles.title}>
          Canonic: As your Lowcode platform of choice
        </h2>
        <p className={styles.subtitle}>Benefits</p>
        <Features
          numPerRow={3}
          features={[
            {
              icon: TimeConsumingIcon,
              title: "Extend at ease",
              subtitle:
                "Extend your App with APIs (both rest and graphql), CMS, integration, and various different data sources. Build with the code editor for complex codes. ",
            },
            {
              icon: AggregatedDataIcon,
              title: "Low expertise barrier",
              subtitle:
                "Add even the most complex of features with the help of the built-in code editor. Custom code editors also offer great customization options.",
            },
            {
              icon: SaveTimeIcon,
              title: "Save your efforts & time",
              subtitle:
                "Work efficiently and export the actual backend code and save your efforts in a matter of minutes.",
            },
          ]}
        />
      </Row>
      <Callout location={location} title="mvp" />
      <CommunityCallout />
    </Layout>
  )
}
export default MVP
